<template>
  <div class="container container-home-secciones">
    <ul class="lista-secciones-home" v-if="items">
      <li v-for="i in items" :key="i.key">
        <a v-if="i.tipo == 1" :href="i.url" target="_blank">
          <img
            class="logo-seccion"
            :src="i.logo"
            :alt="'Logo de la sección' + i.name"
          />
          <h5>{{ i.name }}</h5>
          <p class="descripcion">{{i.description}}</p>
        </a>
        <router-link v-if="i.tipo == 2" :to="'/seccion/' + i.key">
          <img
            class="logo_seccion"
            :src="i.logo"
            :alt="'Logo de la sección' + i.name"
          />
          <h5>{{ i.name }}</h5>
          <p class="descripcion">{{i.description}}</p>
        </router-link>
        
      </li>
    </ul>
  </div>
  
</template>

<script>
  import firebase from "../firebase";

  export default {
      name: "PubHomeSecciones",
      data() {
        return {
          show: false,
          ref: firebase.firestore().collection("secciones"),
          items: {},
        };
      },
      created() {
        this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
          this.items = [];
          querySnapshot.forEach((doc) => {
            this.items.push({
              key: doc.id,
              name: doc.data().name,
              description: doc.data().description,
              tipo: doc.data().tipo,
              logo: doc.data().logo,
              url: doc.data().url,
            });
          });
        });
      }
  }
</script>
  
<style lang="scss" scoped>
@import "../scss/abstracts/variables";

  .container-home-secciones {
    padding-top: 0rem;
    padding-bottom: 1rem;
    text-align: center;
    @include media-breakpoint-up(md) {
      padding-top: 1rem;
    }

    .lista-secciones-home {
      padding-left: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;

      li {
        display: inline-block;
        width: 120px;
        height: 150px;
        margin: 1rem 0.5rem;
        padding: 0.5rem;
        border: 1px solid #c9cacb;
        @include media-breakpoint-up(md) {
          width: 170px;
          height: 190px;
          margin: 1rem 1.5rem;
        }
        a {
          text-decoration: none;
          color: rgb(53, 49, 49);
          width: 100%;
          //height: 100%;
          display: contents;
          padding: 1rem;
        }
        .logo_seccion {
          width: 75px;
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;
          @include media-breakpoint-up(md) {
            width: 80px;
          }
        }
        h5 {
          font-size: 0.75rem;
          font-weight: 500;
          @include media-breakpoint-up(md) {
            font-size: 1rem;
          }
        }

        .descripcion {
          font-size: 0.6rem;
          //color: white;
          font-weight: 300;
          @include media-breakpoint-up(md) {
            font-size: 0.75rem;
          }
        }

        // &:nth-child(1n) {
        //   background: #4f5f6d;
        // }
        // &:nth-child(2n) {
        //   background: #778f9e;
        // }
        // &:nth-child(3n) {
        // background: $color-03;
        // }
        // &:nth-child(4n) {
        //   background: $color-04;
        // }
        // &:nth-child(5n) {
        //   background: $color-05;
        // }
        // &:nth-child(6n) {
        //   background: $color-06;
        // }

        &:hover {
          background: #f7f7f7;
        }
      }
      
    }
  }
</style>
<template>
  <nav class="navbar fixed-top shadow-sm">
    <div class="container">
      <div>
        <img src="../assets/logos.svg" alt="Logos" class="logocongreso">
      </div>
      <div class="header">
        <router-link :to="{ name: 'Home' }">
          <svg version="1.1" class="icon-home" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
            <g>
              <polygon class="base" points="256,152.96 79.894,288.469 79.894,470.018 221.401,470.018 221.401,336.973 296.576,336.973 296.576,470.018 
                432.107,470.018 432.107,288.469 		" />
            </g>

            <g>
              <polygon class="techo" points="439.482,183.132 439.482,90.307 365.316,90.307 365.316,126.077 256,41.982 0,238.919 35.339,284.855 
                256,115.062 476.662,284.856 512,238.92 		" />
            </g>
          </svg>
        </router-link>
        <router-link :to="{ name: 'Admin' }" class="candado">
          <svg version="1.1" class="icon-home" id="candado" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
            <g>
              <path class="cerradura"
                d="M298.885,372.957C309.746,361.839,316,346.795,316,331c0-33.084-26.916-60-60-60c-33.084,0-60,26.916-60,60
                c0,15.795,6.254,30.839,17.115,41.957l-16.575,60.052c-1.246,4.514-0.312,9.352,2.525,13.078S206.317,452,211,452h90
                c4.683,0,9.098-2.187,11.935-5.913c2.837-3.726,3.771-8.563,2.525-13.078L298.885,372.957z M267.442,371.793L281.299,422H230.7
                l13.857-50.207c1.684-6.101-0.643-12.603-5.816-16.25C230.764,349.918,226,340.743,226,331c0-16.542,13.458-30,30-30
                s30,13.458,30,30c0,9.743-4.764,18.918-12.742,24.543C268.085,359.191,265.758,365.692,267.442,371.793z" />
            </g>
            <g>
              <path class="candado_base" d="M406,211v-61C406,67.29,338.71,0,256,0S106,67.29,106,150v61c-24.813,0-45,20.187-45,45v211c0,24.813,20.187,45,45,45h300
                c24.813,0,45-20.187,45-45V256C451,231.187,430.813,211,406,211z M136,150c0-66.168,53.832-120,120-120s120,53.832,120,120v61h-30
                v-61c0-49.626-40.374-90-90-90c-49.626,0-90,40.374-90,90v61h-30V150z M316,150v61H196v-61c0-33.084,26.916-60,60-60
                S316,116.916,316,150z M421,467c0,8.271-6.729,15-15,15H106c-8.271,0-15-6.729-15-15V256c0-8.271,6.729-15,15-15h300
                c8.271,0,15,6.729,15,15V467z" />
            </g>
          </svg>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import AtomIconCongreso from "./AtomLogoXVI3erAnioHorizontal.vue";
import AtomIconIIL from "./AtomLogoIIL.vue"

export default {
  name: 'PubHeader',
  components: {
    AtomIconCongreso,
    AtomIconIIL
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

.navbar {
  background-color: #fcfcfc;
  padding-top: 0;
  padding-bottom: 0;
  height: 80px;

  @include media-breakpoint-up(md) {
    height: 90px;
  }
}

.logocongreso {
  width: 180px;
  //padding-left: 1rem;
  @include media-breakpoint-up(md) {
    width: 300px;
    //padding-left: 2rem;
  }
}

.icon-home {
  width: 30px;
  height: 30px;
  margin: 0 0.25rem;
  @include media-breakpoint-up(md) {
    width: 40px;
    height: 40px;
  }

  .techo,
  .base,
  .candado_base,
  .cerradura {
    fill: $color-text;
  }

  &:hover {
    .techo,
    .cerradura {
      fill: #778f9e;
    }
  }
}
#candado {
  width: 25px;
}

// Animation Menu Icon
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.6s;
}
.slide-fade-enter,
.slide-fade-leave-active {
  opacity: 0;
}
.slide-fade-enter {
  transform: translateX(31px);
}
.slide-fade-leave-active {
  transform: translateX(-31px);
}

// Dropdown Menu Animation

.dropdown-enter-active,
.dropdown-leave-active {
  transition: all 1s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>

import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const settings = { timestampsInSnapshots: true };

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC2fc0rVhmkvd88BeltvvqPTttBu9KPqN4",
  authDomain: "foro-ayuntamientos-podlegqroo.firebaseapp.com",
  projectId: "foro-ayuntamientos-podlegqroo",
  storageBucket: "foro-ayuntamientos-podlegqroo.appspot.com",
  messagingSenderId: "168666729771",
  appId: "1:168666729771:web:8af3d4482515e28055c96e",
  measurementId: "G-VQ2E1REWCZ"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings(settings);

export default firebase;
<template>
  <div>
    <b-modal
      ref="modal-avisos"
      size="lg"
      hide-footer
      hide-header
      title="Avisos"
    >
      <div class="d-block">
        <img
          class="logocongresomodal"
          src="../assets/logos/XVI-logo-anio2-gris-2020.svg"
          alt=""
        />
        <h5 class="titulo-modal">Avisos</h5>
        <div class="container container-avisos">
          <div
            class="aviso shadow-sm"
            v-for="a in avisos"
            :key="a.key"
            v-html="a.aviso"
          ></div>
        </div>
      </div>
    </b-modal>
    <div class="container-fluid container-marco-inicio">
      <div class="container-bienvenida d-flex">
        <img
          class="escudos-foro"
          src="../assets/escudos.svg"
          alt="Escudos"
        />
      </div>
    </div>
    <PubHomeSecciones />
  </div>
</template>

<script>
import firebase from "../firebase";
//import router from "../router";
import PubHomeSecciones from "../components/PubHomeSecciones.vue"
import MarcoJuridicoIIL from "../components/MarcoJuridicoIIL.vue"
import MarcoJuridicoUIG from "../components/MarcoJuridicoUIG.vue"
import MarcoJuridicoUAF from "../components/MarcoJurídicoUAF.vue"

export default {
  name: "Home",
  components: {
    PubHomeSecciones,
    MarcoJuridicoIIL,
    MarcoJuridicoUIG,
    MarcoJuridicoUAF
  },
  data() {
    return {
      show: false,
      refAvisos: firebase.firestore().collection("avisos"),
      avisos: {},
      modal: false,
    };
  },
  created() {
    this.refAvisos.where("activo", "==", true).onSnapshot((querySnapshot) => {
      this.avisos = [];
      querySnapshot.forEach((doc) => {
        this.avisos.push({
          key: doc.id,
          aviso: doc.data().aviso,
        });
      });

      if (this.avisos.length != 0) {
        this.showModal();
      }
    });
  },
  methods: {
    showModal() {
      this.$refs["modal-avisos"].show();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

.container-marco-inicio {
  padding: 6rem 1rem 0.5rem;
  // margin-bottom: 0.5rem;
  
  background-image: url(../assets/elemetos-01.svg), url(../assets/elemetos-02.svg);
  background-position: top 80px right, bottom left;
  background-repeat: no-repeat, no-repeat;
  background-size: 150px, 150px; 
  background-color: #f7f7f7;
  
  @include media-breakpoint-up(md) {
    padding: 6rem 1rem 2rem;
    background-size: 280px, 280px;
    background-position: top 90px right, bottom left;
  }
  @include media-breakpoint-up(lg) {
    background-size: 400px, 400px;
  }

  .container-bienvenida {
    //width: 280px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(md) {
      width: 80%;
      max-width: 700px;
    }
    .escudos-foro {
      margin: 3rem 1rem;
      width: 200px;
      @include media-breakpoint-up(md) {
        margin-right: 2rem;
        width: 350px;
      }
      @include media-breakpoint-up(lg) {
        margin-right: 2rem;
        width: 350px;
      }
    }
    .container-bienvenida-texto {
      padding: 1rem 2rem;
      text-align: center;
    }
    .ilustra-inicio {
      width: 50%;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        width: 350px;
        margin-bottom: 2rem;
      }
    }
    .titulo-micrositio {
      font-family: $font-01;
      color: white;
      font-size: 1rem;
      font-weight: 600;
      margin-top: 1rem;
      margin-bottom: 1rem;
      @include media-breakpoint-up(md) {
        font-size: 1.9rem;
        margin-top: 1.75rem;
      }
    }
    p {
      font-size: 0.7rem;
      font-weight: 300;
      
      @include media-breakpoint-up(md) {
        font-size: 0.85rem;
      }
    }
    .boton-bienvenida {
      background: none;
      font-size: 0.85rem;
      padding:0.5rem 2.5rem;
      border: 1px solid white;
      border-radius: 0;
      margin: 0.5rem;
      margin-top: 0;
      @include media-breakpoint-up(md) {
        margin: 1rem;
        font-size: 1rem;
      }
      &:hover {
        background: white;
        color: black;
      }
    }

    
  }
}
.TituloModal {
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.accordion {
  margin-bottom: 2rem;
  h3 {
    font-size: 1rem;
    margin: 0.25rem;
    text-align: center;
    text-transform: uppercase;
    &:hover {
      color: $color-bg-01;
    }
  }
}

.container-historia {
  margin: 1rem;
  @include media-breakpoint-up(lg) {
    margin: 1rem 2rem;
  }
  .TituloModalHistoria {
    font-family: $font-01;
    font-size: 1.25rem;
    text-align: center;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.75rem;
    }
  }
  p {
    font-family: $font-02;
    font-weight: 300;
    font-size: 1rem;
    text-align: justify;
    @include media-breakpoint-up(lg) {
      font-size: 1.15rem;
    }
  }

  h3 {
    font-family: $font-01;
    font-size: 1.15rem;
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.25rem;
    }
  }
  ul {
    padding-left: 1rem;
    @include media-breakpoint-up(md) {
      padding-left: 2rem;
    }
    li {
      padding: 1rem 1.5rem;
      border-left: 1px solid black;
      h5 {
        margin: 0;
        font-size: 0.8rem;
        font-family: $font-01;
        @include media-breakpoint-up(lg) {
          font-size: 1.1rem;
        }
      }
      span {
        font-size: 0.75rem;
        font-weight: 700;
        @include media-breakpoint-up(lg) {
          font-size: 0.9rem;
        }
      }

      // &:first-child {

      //   &::before {
      //     background:red;
      //     border-color: rgb(34, 34, 34);

      //     @include media-breakpoint-up(md) {
      //       background: blue;
      //     }
      //   }
      // }

      // &:last-child {
      //   padding-bottom: 0.5rem;
      // }

      &::before {
        border: 1px solid black;
        background: #e0e0e0;
        width: 11px;
        height: 11px;
        display: block;
        position: absolute;
        margin-left: -30px;
        margin-top: 5px;
        border-radius: 50%;
        content: "";

        // //margin-top: 1px;
        // @include media-breakpoint-up(lg) {
        //   border: 2px solid yellow;
        //   width: 17px;
        //   height: 17px;
        //   margin-left: -41px;
        // }
      }
    }
  }

  .imagen-titular {
    display: block;
    width: 120px;
    margin: 0 auto;
    margin-bottom: 1rem;
    @include media-breakpoint-up(sm) {
      float: left;
      width: 150px;
      margin: 0.5rem 2rem 1rem 0.5rem;
    }
    @include media-breakpoint-up(lg) {
      width: 250px;
    }
  }
  blockquote {
    margin: 1rem;
    @include media-breakpoint-up(lg) {
      margin: 2rem 8rem 1rem;
    }
  }
  .cabecera-firma, .nombre-firma {
    font-size: 1rem;
    text-align: center;
    text-transform: uppercase;
    @include media-breakpoint-up(lg) {
    font-size: 1.2rem;
    }
  }
  .cabecera-firma {
    margin-top: 2.5rem;
  }
}
</style>
